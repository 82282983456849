<template>
    <component :is="sectionComponent" padding-top="10px" padding-bottom="10px">
        <component
            :is="textComponent"
            font-family="'Arial', 'Helvetica', 'sans-serif'"
            line-height="18px"
            font-size="13px"
            color="#4A4A4A"
        >
            paysafecard classic <br />
            ID Terminal: {{ terminalId }} <br />
            Fecha: {{ date }} <br />
            Hora: {{ time }} <br />
            No trazo: {{ traceCode }} <br />
            No documento: {{ transactionId }} <br /><br />

            Importe: {{ value }} <br />
            Num. Serie: {{ serial }} <br /><br />

            Codigo PIN: {{ code }} <br />
        </component>

        <component :is="hrComponent" border-color="#D7DFE2" border-width="1px"></component>

        <component
            :is="textComponent"
            font-family="'Arial', 'Helvetica', 'sans-serif'"
            line-height="18px"
            font-size="13px"
            color="#4A4A4A"
        >
            <p>ESTE PRODUCTO NO ADMITE DEVOLUCIÓN</p>
            <p>Atención: advertencia para los puntos de venta</p>
            <br />
            <p>¿Se encuentra el cliente personalmente en su tienda?</p>
            <ul>
                <li>¡paysafecard no se puede pedir nunca por teléfono o por correo electrónico!</li>
                <li>
                    Si el cliente no se encuentra personalmente en la tienda, es necesario cancelar la transacción de
                    inmediato.
                </li>
                <li>
                    Atención al cliente Sitio web:
                    <a target="_blank" href="https://www.paysafecard.com/help">www.paysafecard.com/help</a>
                    paysafecard es un producto de dinero electrónico emitido y gestionado como forma de pago por
                    <strong>
                        <a target="_blank" href="https://www.paysafecard.com/">www.paysafecard.com</a> Schweiz GmbH
                    </strong>
                </li>
                <li>
                    Son válidas las condiciones comerciales generales para clientes de paysafecard que se pueden
                    consultar en
                    <strong>
                        <a target="_blank" href="https://www.paysafecard.com/">www.paysafecard.com</a>
                    </strong>
                </li>
                <li>
                    En general, es válido lo siguiente: ¡no es posible realizar canjes ni aceptar devoluciones en el
                    punto de venta/en la tienda!
                </li>
            </ul>
        </component>
    </component>
</template>

<script>
export default {
    props: ['isEmail', 'value', 'code', 'serial', 'terminalId', 'traceCode', 'date', 'time', 'transactionId'],

    computed: {
        sectionComponent() {
            return this.isEmail ? 'mj-section' : 'div';
        },

        textComponent() {
            return this.isEmail ? 'mj-text' : 'div';
        },

        hrComponent() {
            return this.isEmail ? 'mj-divider' : 'hr';
        },
    },
};
</script>
